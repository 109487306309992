import { Component, OnInit } from '@angular/core';
import { AttendeeListEntry } from './attendeeListEntry';
import { Router } from '@angular/router';
import { RegistrationService } from '../../../registration/registration-service/registration.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogDeleteAttendeeComponent } from './dialogs/dialog-delete-attendee/dialog-delete-attendee.component';

@Component({
  selector: 'app-attendee-list',
  templateUrl: './attendee-list.component.html',
  styleUrls: ['./attendee-list.component.scss'],
})
export class AttendeeListComponent implements OnInit {
  attendeeList: AttendeeListEntry[] = [];
  displayedColumns: string[] = ['name', 'category', 'teilnahme', 'menu'];

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    this.attendeeList = this.registrationService.getAttendeeList();
  }

  openRegistrationForm(attendeeId: string): void {
    this.router.navigate(['teilnehmer', attendeeId]);
  }

  deleteAttendeeById(attendeeId: string): void {
    const dialogRef = this.dialog.open(DialogDeleteAttendeeComponent, {
      data: attendeeId,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.registrationService.deleteAttendee(attendeeId);

        this.loadData();
        this.redirectTo('start');
      }
    });
  }

  redirectTo(uri: string): void {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }
}
