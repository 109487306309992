import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { GeneralForm } from '../registration-service/general.form';
import { RegistrationService } from '../registration-service/registration.service';

@Component({
  selector: 'app-general-form',
  templateUrl: './general-form.component.html',
  styleUrls: ['./general-form.component.scss'],
})
export class GeneralFormComponent implements OnInit, AfterViewInit {
  @Output() submitted = new EventEmitter<GeneralForm>();

  @ViewChild('form') form: NgForm;

  private isValid = false;

  public generalForm: GeneralForm = {
    Adresse: { Hausnummer: '', Ort: '', Plz: '', Strasse: '' },
    Kontakt: {
      Vorname: '',
      Nachname: '',
      Festnetz: '',
      Handy: '',
      WeiteresTelefon: '',
      Email: '',
    },
  };

  constructor(private registrationService: RegistrationService) {}

  ngOnInit(): void {
    this.loadData();
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    this.form.statusChanges.subscribe(
      (status) => (this.isValid = status === 'VALID')
    );
  }

  get valid(): boolean {
    return this.isValid;
  }

  get invalid(): boolean {
    return !this.isValid;
  }

  onSubmit(form: NgForm): void {
    this.submitted.emit(this.generalForm);
  }

  submit(): void {
    this.form.ngSubmit.emit();
  }

  loadData(): void {
    const generalFromData = this.registrationService.getGeneral();
    if (generalFromData) {
      this.generalForm = generalFromData;
    }
  }
}
