import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService } from '../../registration/registration-service/registration.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogSendEnrollmentComponent } from './dialogs/dialog-send-enrollment/dialog-send-enrollment.component';
import * as applicationData from '../../../assets/data/appData.json';
import { CostService } from './cost-list/cost-service/cost.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public today = new Date();
  appData = applicationData;
  successfullySubmitted = false;
  submissionError = false;
  confirmationEmailAddress = '';
  disabled = false;
  isLoading = false;

  submissionText = '';
  submissionErrorText =
    'Deine Anmeldung konnte leider nicth versendet werden. Bitte versuche es später erneut.';

  constructor(
    private registrationService: RegistrationService,
    private costService: CostService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.disabled = this.today > new Date(this.appData.daten.anmeldeschluss);
  }

  ngOnInit(): void {}

  get verspaetet(): boolean {
    return this.today > new Date(this.appData.daten.verspaetung);
  }

  get registrationCompleted(): boolean {
    return this.registrationService.isRegistrationComplete();
  }

  openGeneralForm(): void {
    this.router.navigate(['kontakt']);
  }

  openRegistrationForm(attendeeId: string): void {
    this.router.navigate(['teilnehmer', attendeeId]);
  }

  send(): void {
    const dialogRef = this.dialog.open(DialogSendEnrollmentComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (!(result === null)) {
        this.submissionError = false;
        this.setLoading(true);
        const email = this.registrationService.getGeneralEmail();
        this.confirmationEmailAddress = email === undefined ? '' : email;
        const attendees = this.registrationService.getAllAttendees();

        if (attendees) {
          const cost = this.costService.getCostList(attendees);
          this.registrationService.submit(cost).subscribe((response) => {
            this.displaySubmissionResult(response);
            this.setLoading(false);
          });
        }
      }
    });
  }

  private displaySubmissionResult(response: {
    success: boolean;
    status: number;
  }): void {
    if (response.success) {
      this.submissionText =
        response.status === 200
          ? 'Deine Anmeldung wurde erfolgreich versendet. Eine Bestätigungsemail wurde an ' +
            this.confirmationEmailAddress +
            ' gesendet.'
          : 'Deine Anmeldung wurde erfolgreich versendet. Eine Bestätigungsemail konnte jedonch nicht an ' +
            this.confirmationEmailAddress +
            ' gesendet werden.';
      this.successfullySubmitted = true;
    } else {
      this.submissionError = true;
    }
  }

  private setLoading(loading: boolean): void {
    this.isLoading = loading;
  }
}
