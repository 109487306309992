<app-form-page>
  <div fxLayout="column">

    <mat-card>
      <mat-card-content>

        {{appData.texte.start}}
        <mat-divider></mat-divider>
        <mat-card-title>Anleitung</mat-card-title>
        {{appData.texte.dashboard}}

        <mat-divider></mat-divider>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" fxLayoutAlign.xs="start">

          <a href="assets/downloads/Ausschreibung.pdf" download>
            <div fxLayout="row">
              <mat-icon>library_books</mat-icon>
              <p>Ausschreibung</p>
          </div>
          </a>

        <a href="https://www.st-bernward-lehrte.de/unsere-gemeinde/gruppen/roederhof/">
          <div fxLayout="row">
            <mat-icon>flare</mat-icon>
              <p>Kath. Pfarrgemeinde ST. Bernward Lehrte</p>
          </div>
        </a>

        <a href="assets/downloads/Packliste.pdf" download>
          <div fxLayout="row">
            <mat-icon>list</mat-icon>
              <p>Packzettel</p>
          </div>
        </a>

          <a href="assets/downloads/Informationen.pdf" download>
            <div fxLayout="row">
              <mat-icon>info</mat-icon>
                <p>Informationen</p>
            </div>
          </a>

        </div>

      </mat-card-content>
    </mat-card>

    <app-testphase></app-testphase>

    <app-dashboard></app-dashboard>

  </div>
</app-form-page>
