<div fxLayout="row" fxLayoutGap="24px" class="heading">
  <button mat-mini-fab color="primary">
    <mat-icon>person</mat-icon>
  </button>
  <h1>Teilnehmer Informationen</h1>
</div>

<form #form="ngForm" (ngSubmit)="onSubmit(form)">
  <mat-card>
    <mat-card-title>Person</mat-card-title>
    <mat-card-content class="content">
      <div fxLayout="column">
        <div
          fxLayout="row wrap"
          fxLayoutGap="16px grid"
          fxLayoutGap.xs="8px grid"
        >
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Vorname</mat-label>
            <input
              matInput
              required
              type="text"
              [(ngModel)]="registrationForm.Person.Vorname"
              name="Vorname"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Nachname</mat-label>
            <input
              matInput
              required
              type="text"
              [(ngModel)]="registrationForm.Person.Nachname"
              name="Nachname"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Geburtsdatum</mat-label>
            <input
              matInput
              [matDatepicker]="picker"
              required
              [formControl]="geburtsdatum"
              name="Geburtsdatum"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #picker
              startView="multi-year"
              [startAt]="startDateBirthdayPicker"
            ></mat-datepicker>
            <mat-hint
            >(Bitte durch Anklicken des Kalendersymbols das Datum auswählen)</mat-hint
            >
          </mat-form-field>
        </div>

        <div
          fxLayout="row wrap"
          fxLayoutGap="16px grid"
          fxLayoutGap.xs="8px grid"
        >
          <mat-radio-group
            [(ngModel)]="registrationForm.Person.Geschlecht"
            name="Geschlecht"
            required
          >
            <mat-radio-button value="Maennlich">männlich</mat-radio-button>
            <mat-radio-button value="Weiblich">weiblich</mat-radio-button>
          </mat-radio-group>
        </div>

        <div
          fxLayout="row wrap"
          fxLayoutGap="16px grid"
          fxLayoutGap.xs="8px grid"
        >
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Handynummer</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="registrationForm.Kontakt.Handy"
              name="Handy"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label>E-Mail</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="registrationForm.Kontakt.Email"
              name="Email"
            />
          </mat-form-field>
        </div>

        <mat-checkbox
          [checked]="registrationForm.Teilnahme.Erstteilnahme"
          (change)="
            registrationForm.Teilnahme.Erstteilnahme =
              !registrationForm.Teilnahme.Erstteilnahme
          "
          >Ich fahre zum ersten Mal mit.</mat-checkbox
        >

        <mat-checkbox
          [checked]="registrationForm.Ermaessigungen.Ermaessigt"
          [disabled]="isChild"
          (change)="
            registrationForm.Ermaessigungen.Ermaessigt =
              !registrationForm.Ermaessigungen.Ermaessigt
          "
          >Ich bin ermäßigungsberechtigt (Kind, Jugendlicher, Schüler, Student,
          Azubi, FSJ´ler, Bufdis, Arbeitslose(r))</mat-checkbox
        >
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Zeltlager</mat-card-title>
    <mat-card-content class="content">
      <div fxLayout="column" style="margin-top: 24px">
        <mat-form-field appearance="outline" style="max-width: 200px">
          <mat-label>Teilnahme</mat-label>
          <mat-select
            required
            name="type"
            [(ngModel)]="registrationForm.Teilnahme.Teilnahmedauer"
            #SelectOption="ngModel"
          >
            <mat-option value="Vollzeit">Vollzeit</mat-option>
            <mat-option value="Teilzeit">Teilzeit</mat-option>
            <mat-option value="Unterbrechung"
              >Teilzeit mit Unterbrechung</mat-option
            >
            <mat-option value="Party" [disabled]="isChild"
              >Nur Partywochenende</mat-option
            >
          </mat-select>
        </mat-form-field>

        <div
          fxLayout="row wrap"
          fxLayoutGap="16px grid"
          fxLayoutGap.xs="8px grid"
        >
          <mat-form-field
            appearance="outline"
            fxFlex
            *ngIf="Teilzeit || Unterbrechung"
          >
            <mat-label>Zeitraum</mat-label>

            <mat-date-range-input [min]="fromDate"
                                  [max]="endDate" required [formGroup]="teilzeitRange" [rangePicker]="picker">
              <input matStartDate formControlName="start"  placeholder="Startdatum" name="von"
                     >
              <input matEndDate formControlName="end" placeholder="Enddatum" name="bis">
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker ></mat-date-range-picker>
          </mat-form-field>

          <mat-form-field
            appearance="outline"
            fxFlex
            *ngIf="Teilzeit || Unterbrechung"
          >
            <mat-label>Kommentar</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="registrationForm.Teilnahme.KommentarErsterZeitraum"
              name="KommentarErsterZeitraum"
            />
          </mat-form-field>
        </div>

        <div
          fxLayout="row wrap"
          fxLayoutGap="16px grid"
          fxLayoutGap.xs="8px grid"
        >
          <mat-form-field
            appearance="outline"
            fxFlex
            *ngIf="Unterbrechung"
          >
            <mat-label>2. Zeitraum</mat-label>

            <mat-date-range-input [min]="teilzeitRange.controls.end.value"
                                  [max]="endDate" required [formGroup]="unterbrechungRange" [rangePicker]="picker2">
              <input matStartDate formControlName="start" placeholder="Startdatum" name="von"
              >
              <input matEndDate formControlName="end" placeholder="Enddatum" name="bis">
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex *ngIf="Unterbrechung">
            <mat-label>Kommentar</mat-label>
            <input
              matInput
              type="text"
              [(ngModel)]="registrationForm.Teilnahme.KommentarZweiterZeitraum"
              name="KommentarZeiterZeitraum"
            />
          </mat-form-field>
        </div>

        <mat-checkbox
          style="margin-top: 24px"
          *ngIf="!isChild"
          [checked]="registrationForm.Teilnahme.Nachlager"
          (change)="
            registrationForm.Teilnahme.Nachlager =
              !registrationForm.Teilnahme.Nachlager
          "
          >Ich nehme am Nachlager teil.</mat-checkbox
        >
        <mat-checkbox
          *ngIf="!isChild"
          [checked]="registrationForm.Sonstiges.Praeventionskurs"
          (change)="
            registrationForm.Sonstiges.Praeventionskurs =
              !registrationForm.Sonstiges.Praeventionskurs
          "
          >Ich habe einen Präventionskurs besucht</mat-checkbox
        >
        <mat-checkbox
          *ngIf="!isChild"
          [checked]="registrationForm.Betreuer.Betreut"
          (change)="
            registrationForm.Betreuer.Betreut =
              !registrationForm.Betreuer.Betreut
          "
          >Ich fahre als Betreuer mit</mat-checkbox
        >

        <mat-label style="margin-top: 24px">Zelt</mat-label>
        <mat-checkbox
          [checked]="registrationForm.Zelt.ZeltVorhanden"
          (change)="
            registrationForm.Zelt.ZeltVorhanden = !registrationForm.Zelt.ZeltVorhanden
          "
          >Ich bringe ein Zelt mit</mat-checkbox
        >

        <div
          *ngIf="registrationForm.Zelt.ZeltVorhanden"
          fxLayout="row wrap"
          fxLayoutGap="16px grid"
          fxLayoutGap.xs="8px grid"
        >
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Personenzahl</mat-label>
            <input
              matInput
              required
              type="number"
              [(ngModel)]="registrationForm.Zelt.ZeltPersonen"
              name="ZeltPersonen"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Länge</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="registrationForm.Zelt.ZeltLaenge"
              name="Laenge"
            />
            <mat-hint>Zeltlänge in Metern</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Breite</mat-label>
            <input
              matInput
              type="number"
              [(ngModel)]="registrationForm.Zelt.ZeltBreite"
              name="Breite"
            />
            <mat-hint>Zeltbreite in Metern</mat-hint>
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Zeltpartner</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="registrationForm.Zelt.Zeltpartner"
            name="Zeltpartner"
          />
          <mat-hint
            >(Mit diesen Personen möchte ich in einem Zelt schlafen)</mat-hint
          >
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="registrationForm.Betreuer.Betreut">
    <mat-card-title>Betreuer</mat-card-title>
    <mat-card-content class="content">
      <div fxLayout="column">
        <span
          >Die JuLeiCa (Jugendleiter/In-Card) dient bundesweit als Legitimation
          und Qualifikationsnachweis für Mitarbeiter-innen in der Jugendarbeit.
          Wir, als Zeltlager der katholischen Gemeinde St. Bernward Lehrte, sind
          angehalten geschulte Betreuer zu haben. Deshalb ist jeder
          Röderhof-Teilnehmer, der mindesten 16 Jahre alt ist, verpflichtet eine
          JuLeiCa vorweisen zu können. Wenn das nicht möglich ist, entscheidet
          die Lagerleitung in Einzelfällen über die Teilnahme am
          Zeltlager.</span
        >

        <mat-checkbox
          [checked]="registrationForm.Betreuer.JuLeiCa"
          (change)="
            registrationForm.Betreuer.JuLeiCa =
              !registrationForm.Betreuer.JuLeiCa
          "
          >Ich habe die JuLeiCa-Ausbildung absolviert</mat-checkbox
        >

        <div
          *ngIf="registrationForm.Betreuer.JuLeiCa"
          fxLayout="row wrap"
          fxLayoutGap="16px grid"
          fxLayoutGap.xs="8px grid"
        >
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Träger</mat-label>
            <input
              matInput
              type="text"
              required
              [(ngModel)]="registrationForm.Betreuer.JuLeiCaTraeger"
              name="Traeger"
            />
            <mat-hint>(Träger der JuLeiCa-Ausbildung)</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Gültig bis</mat-label>
            <input
              matInput
              required
              [matDatepicker]="picker"
              [formControl]="juLeiCaGueltigBisDatum"
              name="gueltigBis"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #picker
              startView="month"
              [startAt]="endDate"
            ></mat-datepicker>
          </mat-form-field>
        </div>

        <div fxLayout="column">
          <mat-checkbox
            [checked]="registrationForm.Betreuer.JuLeiCaAngemeldet"
            (change)="
              registrationForm.Betreuer.JuLeiCaAngemeldet =
                !registrationForm.Betreuer.JuLeiCaAngemeldet
            "
            >Ich bin angemeldet zu folgendem JuLeiCa-Kurs:</mat-checkbox
          >

          <mat-form-field
            appearance="outline"
            fxFlex
            *ngIf="registrationForm.Betreuer.JuLeiCaAngemeldet"
          >
            <mat-label>Kurs</mat-label>
            <input
              matInput
              required
              type="text"
              [(ngModel)]="registrationForm.Betreuer.JuLeiCaAngemeldetKurs"
              name="Kurs"
            />
            <mat-hint>(Kursdaten)</mat-hint>
          </mat-form-field>

          <mat-checkbox
            [checked]="registrationForm.Betreuer.POK"
            (change)="
              registrationForm.Betreuer.POK = !registrationForm.Betreuer.POK
            "
            >Ich habe einen POK (Persönlichkeitsorientierter Kurs)
            absolviert</mat-checkbox
          >

          <mat-checkbox
            [checked]="registrationForm.Betreuer.VorerfahrungJugendarbeit"
            (change)="
              registrationForm.Betreuer.VorerfahrungJugendarbeit =
                !registrationForm.Betreuer.VorerfahrungJugendarbeit
            "
            >Ich habe folgende Vorerfahrung in der Kinder- oder
            Jugendarbeit:</mat-checkbox
          >
          <mat-form-field
            appearance="outline"
            fxFlex
            *ngIf="registrationForm.Betreuer.VorerfahrungJugendarbeit"
          >
            <mat-label>Vorerfahrung</mat-label>
            <input
              matInput
              required
              type="text"
              [(ngModel)]="registrationForm.Betreuer.VorerfahrungsList"
              name="Vorerfahrung"
            />
            <mat-hint>(alle relevanten Vorerfahrungen)</mat-hint>
          </mat-form-field>

          <mat-checkbox
            [checked]="registrationForm.Betreuer.Winterroeder"
            (change)="
              registrationForm.Betreuer.Winterroeder =
                !registrationForm.Betreuer.Winterroeder
            "
            >Ich nehme teil / habe teilgenommen am Vorbereitungswochenende
            WinterRöder 2022</mat-checkbox
          >

          <mat-checkbox
            [checked]="registrationForm.Betreuer.Lenzroeder"
            (change)="
              registrationForm.Betreuer.Lenzroeder =
                !registrationForm.Betreuer.Lenzroeder
            "
            >Ich nehme teil / habe teilgenommen am Vorbereitungswochenende
            LenzRöder 2022</mat-checkbox
          >
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Vorlieben</mat-card-title>
    <mat-card-content class="content">
      <div fxLayout="column">
        <mat-checkbox
          [checked]="registrationForm.Vorlieben.Vegtarisch"
          (change)="
            registrationForm.Vorlieben.Vegtarisch =
              !registrationForm.Vorlieben.Vegtarisch
          "
          >Ich bin Vegetarier</mat-checkbox
        >

        <mat-checkbox
          [checked]="registrationForm.Vorlieben.DarfSchwimmen"
          (change)="
            registrationForm.Vorlieben.DarfSchwimmen =
              !registrationForm.Vorlieben.DarfSchwimmen
          "
          >Ich darf schwimmen</mat-checkbox
        >

        <mat-form-field
          appearance="outline"
          fxFlex
          *ngIf="registrationForm.Vorlieben.DarfSchwimmen"
        >
          <mat-label>Schwimmabzeichen</mat-label>
          <input
            matInput
            required
            type="text"
            [(ngModel)]="registrationForm.Vorlieben.Schwimmabzeichen"
            name="Schwimmabzeichen"
          />
        </mat-form-field>

        <span style="margin-bottom: 8px; margin-top: 16px"
          >Klettern und Wasserski sind Aktionen, welche zusätzlich zum Hauptprogramm stattfinden und freiwillig sind. Für diese Aktionen muss ein zusätzlicher Betrag entrichtet werden</span
        >

        <mat-checkbox
          [checked]="registrationForm.Vorlieben.Klettern"
          (change)="
            registrationForm.Vorlieben.Klettern =
              !registrationForm.Vorlieben.Klettern
          "
          *ngIf="age != undefined && age > 11"
          >Ich will und darf klettern (20 € laut Anmeldeabschnitt)</mat-checkbox
        >

        <mat-checkbox
          [hidden]="!isSegelfliegen"

          [checked]="registrationForm.Vorlieben.Segelfliegen"
          (change)="
            registrationForm.Vorlieben.Segelfliegen =
              !registrationForm.Vorlieben.Segelfliegen
          "
          >Ich will und darf in einem Segelflugzeug mitfliegen (20 € laut
          Anmeldeabschnitt)</mat-checkbox
        >

        <mat-checkbox
          [checked]="registrationForm.Vorlieben.Wasserski"
          (change)="
            registrationForm.Vorlieben.Wasserski =
              !registrationForm.Vorlieben.Wasserski
          "
        >Ich will und darf Wasserski fahren (20 € laut
          Anmeldeabschnitt)</mat-checkbox
        >
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Gesundheit</mat-card-title>
    <mat-card-content class="content">
      <div fxLayout="column">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Krankenkasse</mat-label>
          <input
            matInput
            required
            type="text"
            [(ngModel)]="registrationForm.Gesundheit.Krankenkasse"
            name="Krankenkasse"
          />
        </mat-form-field>

        <mat-label>Hausarzt</mat-label>
        <div
          fxLayout="row wrap"
          fxLayoutGap="16px grid"
          fxLayoutGap.xs="8px grid"
        >
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Name</mat-label>
            <input
              matInput
              required
              type="text"
              [(ngModel)]="registrationForm.Gesundheit.Hausarzt.HausarztName"
              name="Hausarzt.Name"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Adresse</mat-label>
            <input
              matInput
              required
              type="text"
              [(ngModel)]="registrationForm.Gesundheit.Hausarzt.HausarztAdresse"
              name="Hausarzt.Adresse"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Telefonnummer</mat-label>
            <input
              matInput
              required
              type="text"
              [(ngModel)]="registrationForm.Gesundheit.Hausarzt.HausarztTelefonnummer"
              name="Hausarzt.Telefonnummer"
            />
          </mat-form-field>
        </div>

        <mat-checkbox
          [checked]="registrationForm.Gesundheit.Tetanusimpfung"
          (change)="
            registrationForm.Gesundheit.Tetanusimpfung =
              !registrationForm.Gesundheit.Tetanusimpfung
          "
          >Ich bin gegen Tetanus geimpft</mat-checkbox
        >

        <mat-checkbox
          [checked]="registrationForm.Gesundheit.Roentgen"
          (change)="
            registrationForm.Gesundheit.Roentgen =
              !registrationForm.Gesundheit.Roentgen
          "
          >Ich darf auf ärztliche Anordnung ohne Rücksprache der Eltern geröntgt
          werden</mat-checkbox
        >

        <mat-checkbox [checked]="true" disabled="true"
          ><span>
            Da wir am Waldrand zelten, werden jedes Jahr Teilnehmer von Zecken
            befallen. Ich bin damit einverstanden, dass die Zecken nach
            Zustimmung der Betroffenen von Lagerleitung, Betreuern oder
            Kochfrauen möglichst schnell entfernt werden. Auf einem Zettel mit
            Verhaltensmaßnahmen bei Zeckenbefall wird die Stelle mit Datum
            vermerkt und dem Teilnehmer bei Abreise mitgegeben.
          </span></mat-checkbox
        >

        <mat-checkbox [checked]="true" disabled="true"
          ><span>
            Ebenso stimme ich hiermit zu, dass mir bzw. meinem Kind
            gegebenenfalls im Lager ohne ärztliche Anordnung ein
            Desinfektionsspray, Salbe gegen Mückenstiche (wie z.B. Fenistil)
            oder Salbe gegen Muskelverspannung, Prellungen (wie z.B.
            Voltaren-Salbe) auf der Haut aufgetragen werden kann.
          </span></mat-checkbox
        >

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Krankheiten</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="registrationForm.Gesundheit.Krankheiten"
            name="Krankheiten"
          />
          <mat-hint>Bitte alle relevanten Krankheiten auflisten</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Allergien</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="registrationForm.Gesundheit.Allergien"
            name="Allergien"
          />
        </mat-form-field>

        <mat-checkbox
          [checked]="registrationForm.Gesundheit.Medikamente.MedikamenteErforderlich"
          (change)="
            registrationForm.Gesundheit.Medikamente.MedikamenteErforderlich =
              !registrationForm.Gesundheit.Medikamente.MedikamenteErforderlich
          "
          >Ich muss regelmäßig Medikamente nehmen</mat-checkbox
        >

        <mat-form-field
          appearance="outline"
          fxFlex
          *ngIf="registrationForm.Gesundheit.Medikamente.MedikamenteErforderlich"
        >
          <mat-label>Medikamente und Einnahme</mat-label>
          <input
            matInput
            required
            type="text"
            [(ngModel)]="registrationForm.Gesundheit.Medikamente.Medikamentenliste"
            name="Liste"
          />
          <mat-hint
            >Bitte alle Medikamente mit Häufigkeit der Einnahme
            auflisten</mat-hint
          >
        </mat-form-field>

        <mat-radio-group
          [(ngModel)]="registrationForm.Gesundheit.Medikamente.MedikamenteEinnahme"
          name="Einnahme"
          required
          *ngIf="registrationForm.Gesundheit.Medikamente.MedikamenteErforderlich"
        >
          <div fxLayout="column">
            <mat-radio-button value="Selbstaendig"
              >Die Medikamente nehme ich selbständig ein.</mat-radio-button
            >
            <mat-radio-button value="Erinnert"
              >Mein Betreuer soll mich an meine Medikamente
              erinnern.</mat-radio-button
            >
            <mat-radio-button value="Betreuer"
              >Die Medikamente erhält mein Betreuer und gibt sie
              mir.</mat-radio-button
            >
          </div>
        </mat-radio-group>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="Vollzeit">
    <mat-card-title>Ermäßigungen</mat-card-title>
    <mat-card-subtitle
      >Nur möglich bei ganzzeitiger Teilnahme
    </mat-card-subtitle>
    <mat-card-content class="content">
      <div fxLayout="column">
        <mat-checkbox
          [checked]="registrationForm.Ermaessigungen.MitgliedPfarrgemeinde"
          (change)="setMitgliedPfarrgemeinde($event)"
          >Mitglied einer Kath. Pfarrgemeinde</mat-checkbox
        >
        <mat-form-field
          appearance="outline"
          fxFlex
          *ngIf="registrationForm.Ermaessigungen.MitgliedPfarrgemeinde"
        >
          <mat-label>Name der Pfarrgemeinde</mat-label>
          <input
            matInput
            type="text"
            required
            [(ngModel)]="registrationForm.Ermaessigungen.NamePfarrgemeinde"
            name="NamePfarrgemeinde"
          />
        </mat-form-field>

        <div fxLayout="column">
          <div style="margin-top: 16px; margin-bottom: 16px;">
            <label id="taetigkeiten-radio-group">Messdiener, BDKJ-Mitglied, Vorbeter oder Gruppenleiter mit gültiger JuLeiCa</label>

            <mat-radio-group name="taetigkeiten" class="taetigkeiten-radio-group" [(ngModel)]="registrationForm.Ermaessigungen.AnzahlTaetigkeiten" (change)="taetigkeitenChanged()">
              <mat-radio-button
                [value]="0"
                [checked]="registrationForm.Ermaessigungen.AnzahlTaetigkeiten === 0"
              >Keine Tätigkeit</mat-radio-button
              >
              <mat-radio-button
                [value]="1"
                [checked]="registrationForm.Ermaessigungen.AnzahlTaetigkeiten === 1"
              >Eine der oben genannten Tätigkeiten</mat-radio-button
              >
              <mat-radio-button [value]="2"
                                [checked]="registrationForm.Ermaessigungen.AnzahlTaetigkeiten === 2"
              >Mehrere der oben genannten Tätigkeiten</mat-radio-button
              >
            </mat-radio-group>
          </div>

          <mat-checkbox
            [checked]="registrationForm.Ermaessigungen.Arbeitslosengeld"
            (change)="setArbeitslosengeld($event)"
          >
            Familien, die von Arbeitslosengeld II oder Sozialhilfe
            leben</mat-checkbox
          >

          <mat-checkbox
            [checked]="registrationForm.Ermaessigungen.SonstigeNachlaesse"
            (change)="setSonstigeNachlaesse($event)"
            >Sonstige Nachlässe oder Zuschläge</mat-checkbox
          >

          <div
            fxLayout="row wrap"
            fxLayoutGap="16px"
            fxLayoutGap.xs="8px"
            *ngIf="registrationForm.Ermaessigungen.SonstigeNachlaesse"
          >
            <mat-form-field appearance="outline" style="width: 100px">
              <mat-label>Betrag</mat-label>
              <input
                matInput
                type="number"
                class="numberField"
                required
                placeholder="0"
                maxlength="3"
                [(ngModel)]="
                  registrationForm.Ermaessigungen.SonstigeNachlaesseBetrag
                "
                (ngModelChange)="setNachlaesseBetrag($event)"
                name="SonstigeNachlaesseBetrag"
              />
              <span matPrefix>€&nbsp;</span>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex>
              <mat-label>Begründung</mat-label>
              <input
                matInput
                type="text"
                required
                [(ngModel)]="
                  registrationForm.Ermaessigungen.SonstigeNachlaesseBegruendung
                "
                name="Begruendung"
              />
            </mat-form-field>
          </div>

          <mat-list>
            <mat-divider inset="true"></mat-divider>
          </mat-list>
          <p style="margin-top: 16px">Summe der Ermäßigungen</p>
          <p>{{ registrationForm.Ermaessigungen.SummeErmaessigungen }}€</p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-content class="content">
      <div fxLayout="column">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Weitere Angaben / Hinweiße / Kommentare</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="registrationForm.Sonstiges.SonstigesText"
            name="SonstigesText"
          />
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</form>
