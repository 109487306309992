<app-form-page>
  <div fxLayout="column">
    <app-registration-form
      #registrationForm
      [attendeeId]="attendeeId"
      (submitted)="createRegistration($event)"
    ></app-registration-form>
    <span class="warningText"
      *ngIf="registrationForm.invalid"
      >Bitte fülle vor dem Speichern alle mit * gekennzeichnete Felder aus</span
    >
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutGap.xs="8px">
      <button
        mat-flat-button
        color="primary"
        fxFlex="nogrow"
        type="submit"
        (click)="registrationForm.submit()"
        [disabled]="registrationForm.invalid || disableAfterSave"
      >
        Speichern
      </button>

      <button
        mat-flat-button
        fxFlex="nogrow"
        type="cancel"
        (click)="openStartpage()"
      >
        Abbrechen
      </button>
    </div>
  </div>
</app-form-page>
