<div fxLayout="row" fxLayoutGap="24px" class="heading">
  <button mat-mini-fab color="primary">
    <mat-icon>assignment</mat-icon>
  </button>
  <h1>Allgemeine Informationen</h1>
</div>

<mat-toolbar fxLayoutGap="24px">
  <span >Angaben zum Hauptansprechpartner - diese werden für mögliche Rückfragen zur
    Anmeldung benötigt.</span>
</mat-toolbar>

<form #form="ngForm" (ngSubmit)="onSubmit(form)">
  <mat-card class="card">
    <mat-card-title>Adresse</mat-card-title>
    <mat-card-content class="content">
      <div
        fxLayout="row wrap"
        fxLayoutGap="16px grid"
        fxLayoutGap.xs="8px grid"
      >
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Vorname</mat-label>
          <input
            matInput
            required
            type="text"
            [(ngModel)]="generalForm.Kontakt.Vorname"
            name="Vorname"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Nachname</mat-label>
          <input
            matInput
            required
            type="text"
            [(ngModel)]="generalForm.Kontakt.Nachname"
            name="Nachname"
          />
        </mat-form-field>
      </div>

      <div fxLayout="column">
        <div
          fxLayout="row wrap"
          fxLayoutGap="16px grid"
          fxLayoutGap.xs="8px grid"
        >
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Straße</mat-label>
            <input
              matInput
              required
              type="text"
              [(ngModel)]="generalForm.Adresse.Strasse"
              name="Strasse"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Hausnummer</mat-label>
            <input
              matInput
              required
              type="text"
              [(ngModel)]="generalForm.Adresse.Hausnummer"
              name="Hausnummer"
            />
          </mat-form-field>
        </div>

        <div
          fxLayout="row wrap"
          fxLayoutGap="16px grid"
          fxLayoutGap.xs="8px grid"
        >
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>PLZ</mat-label>
            <input
              matInput
              required
              type="number"
              [(ngModel)]="generalForm.Adresse.Plz"
              name="Plz"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" fxFlex>
            <mat-label>Ort</mat-label>
            <input
              matInput
              required
              type="text"
              [(ngModel)]="generalForm.Adresse.Ort"
              name="Ort"
            />
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="card">
    <mat-card-title>Kontakt</mat-card-title>
    <mat-card-content class="content">
      <div
        fxLayout="column wrap"
        fxLayoutGap="16px grid"
        fxLayoutGap.xs="8px grid"
      >
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Handynummer</mat-label>
          <input
            matInput
            required
            type="text"
            [(ngModel)]="generalForm.Kontakt.Handy"
            name="Handy"
          />
          <mat-hint>(Ansprechpartner für Rückfragen zur Anmeldung)</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Festnetznummer</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="generalForm.Kontakt.Festnetz"
            name="Festnetz"
          />
          <mat-hint>(Ansprechpartner für Rückfragen zur Anmeldung)</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Weiteres Telefon</mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="generalForm.Kontakt.WeiteresTelefon"
            name="WeiteresTelefon"
          />
          <mat-hint>(berufl./Oma usw./bitte erläutern!)</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex>
          <mat-label>Email</mat-label>
          <input
            matInput
            required
            type="email"
            [email]="true"
            [(ngModel)]="generalForm.Kontakt.Email"
            name="Email"
          />
        </mat-form-field>
      </div>
    </mat-card-content>
  </mat-card>
</form>
