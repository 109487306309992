import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  Einnahmeform,
  Geschlecht,
  AttendeeForm,
  Teilnahmedauer,
} from '../registration-service/attendee.form';
import {NgForm, FormControl, FormGroup} from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { RegistrationService } from '../registration-service/registration.service';
import * as appData from '../../../assets/data/appData.json';

@Component({
  selector: 'app-registration-form',
  templateUrl: './registration.form.component.html',
  styleUrls: ['./registration.form.component.scss'],
})
export class RegistrationFormComponent implements OnInit, AfterViewInit {
  @Output() submitted = new EventEmitter<AttendeeForm>();
  @ViewChild('form') form: NgForm;

  appData = appData;
  isSegelfliegen: boolean = false;

  startDateBirthdayPicker = new Date(2010, 0, 1);
  fromDate = new Date(this.appData.daten.start);
  endDate = new Date(this.appData.daten.ende);

  // Child is until the age of 16
  public isChild = false;
  private isValid = false;
  public age: number | undefined = undefined;

  teilzeitRange = new FormGroup({
    start: new FormControl<string>(''),
    end: new FormControl<string>(''),
  });

  unterbrechungRange = new FormGroup({
    start: new FormControl<string>(''),
    end: new FormControl<string>(''),
  });

  geburtsdatum = new FormControl<string>('');
  juLeiCaGueltigBisDatum = new FormControl<string>('');

  public registrationForm: AttendeeForm = {
    AttendeeId: '',
    Betreuer: {
      JuLeiCaAngemeldet: false,
      JuLeiCaAngemeldetKurs: '',
      Betreut: false,
      JuLeiCaGueltigBis: '',
      JuLeiCa: false,
      Lenzroeder: false,
      POK: false,
      JuLeiCaTraeger: '',
      VorerfahrungJugendarbeit: false,
      VorerfahrungsList: '',
      Winterroeder: false,
    },
    Ermaessigungen: {
      Arbeitslosengeld: false,
      Ermaessigt: false,
      AnzahlTaetigkeiten: 0,
      MitgliedPfarrgemeinde: false,
      NamePfarrgemeinde: '',
      SonstigeNachlaesse: false,
      SonstigeNachlaesseBegruendung: '',
      SonstigeNachlaesseBetrag: 0,
      SummeErmaessigungen: 0,
    },
    Gesundheit: {
      Allergien: '',
      Hausarzt: {
        HausarztAdresse: '',
        HausarztName: '',
        HausarztTelefonnummer: '',
      },
      Krankenkasse: '',
      Krankheiten: '',
      Medikamente: {
        MedikamenteEinnahme: Einnahmeform.Selbstaendig,
        MedikamenteErforderlich: false,
        Medikamentenliste: '',
      },
      Roentgen: false,
      Tetanusimpfung: false,
    },
    Kontakt: { Email: '', Handy: '' },
    Person: {
      Geburtsdatum: '',
      Geschlecht: Geschlecht.Unbekannt,
      Nachname: '',
      Vorname: '',
      Alter: 0,
    },
    Sonstiges: { Praeventionskurs: false, SonstigesText: '' },
    Teilnahme: {
      TeilnahmeBis: '',
      TeilnahmeBisZweiterZeitraum: '',
      Erstteilnahme: false,
      KommentarErsterZeitraum: '',
      KommentarZweiterZeitraum: '',
      Nachlager: false,
      Teilnahmedauer: Teilnahmedauer.Vollzeit,
      TeilnahmeVon: '',
      TeilnahmeVonZweiterZeitraum: '',
      TeilnahmeTage: 0,
    },
    Vorlieben: {
      Klettern: false,
      Schwimmabzeichen: '',
      DarfSchwimmen: false,
      Segelfliegen: false,
      Vegtarisch: false,
      Wasserski: false,
    },
    Zelt: {
      ZeltBreite: 0,
      ZeltLaenge: 0,
      ZeltVorhanden: false,
      ZeltPersonen: 0,
      Zeltpartner: '',
    },
  };

  constructor(private registrationService: RegistrationService) {}

  @Input() attendeeId = '';

  get valid(): boolean {
    return this.isValid;
  }

  get Teilzeit(): boolean {
    return (
      this.registrationForm.Teilnahme.Teilnahmedauer === Teilnahmedauer.Teilzeit
    );
  }

  get Vollzeit(): boolean {
    return (
      this.registrationForm.Teilnahme.Teilnahmedauer === Teilnahmedauer.Vollzeit
    );
  }

  get Unterbrechung(): boolean {
    return (
      this.registrationForm.Teilnahme.Teilnahmedauer ===
      Teilnahmedauer.Unterbrechung
    );
  }

  get invalid(): boolean {
    return !this.isValid;
  }

  onSubmit(form: NgForm): void {
    this.submitted.emit(this.registrationForm);
  }

  ngOnInit(): void {
    if (this.attendeeId) {
      const registration = this.registrationService.getAttendee(
        this.attendeeId
      );

      if (registration) {
        this.registrationForm = registration;

        this.setGeburtsdatumForm();
        this.setJuLeiCaGueltigBisDatumForm();
        this.setTeilzeitRange();
        this.setUnterbrechungRange();
      }
    }
  }

  ngAfterViewInit(): void {
    // @ts-ignore
    this.form.statusChanges.subscribe(
      (status) => (this.isValid = this.isFormValid())
    );

    this.teilzeitRange.valueChanges.subscribe((x) => this.setTeilzeitDates());
    this.teilzeitRange.statusChanges.subscribe(
      (status) => (this.isValid = this.isFormValid())
    );
    this.unterbrechungRange.valueChanges.subscribe((x) =>
      this.setUnterbrechungDates()
    );
    this.unterbrechungRange.statusChanges.subscribe(
      (status) => (this.isValid = this.isFormValid())
    );

    this.geburtsdatum.valueChanges.subscribe((x) => this.setGeburtsdatum(x));
    this.geburtsdatum.statusChanges.subscribe(
      (status) => (this.isValid = this.isFormValid())
    );

    this.juLeiCaGueltigBisDatum.valueChanges.subscribe((x) =>
      this.setJuLeiCaGueltigBisDatum(x)
    );
    this.juLeiCaGueltigBisDatum.statusChanges.subscribe(
      (status) => (this.isValid = this.isFormValid())
    );
  }

  submit(): void {
    this.form.ngSubmit.emit();
  }

  calculateAge(geburtsdatum: Date): number {
    const ageDifMs = this.fromDate.getTime() - geburtsdatum.getTime();
    const ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  private SetIsChildProperties(): void {
    if (this.isChild) {
      this.registrationForm.Ermaessigungen.Ermaessigt = true;
      this.registrationForm.Teilnahme.Nachlager = false;
      this.registrationForm.Sonstiges.Praeventionskurs = false;
      this.registrationForm.Betreuer.Betreut = false;

      if (
        this.registrationForm.Teilnahme.Teilnahmedauer.valueOf() === 'Party'
      ) {
        this.registrationForm.Teilnahme.Teilnahmedauer =
          Teilnahmedauer.Vollzeit;
      }
    }
  }

  calculateErmaessigungen(): void {
    let reductions = 0;

    if (this.registrationForm.Ermaessigungen.MitgliedPfarrgemeinde) {
      reductions -= 30;
    }

    if (this.registrationForm.Ermaessigungen.AnzahlTaetigkeiten === 1) {
      reductions -= 30;
    }

    if (this.registrationForm.Ermaessigungen.AnzahlTaetigkeiten > 1) {
      reductions -= 45;
    }

    if (this.registrationForm.Ermaessigungen.Arbeitslosengeld) {
      reductions -= 98;
    }

    if (
      this.registrationForm.Ermaessigungen.SonstigeNachlaesse &&
      this.registrationForm.Ermaessigungen.SonstigeNachlaesseBetrag
    ) {
      reductions -=
        this.registrationForm.Ermaessigungen.SonstigeNachlaesseBetrag;
    }

    this.registrationForm.Ermaessigungen.SummeErmaessigungen = reductions;
  }

  setMitgliedPfarrgemeinde($event: MatCheckboxChange): void {
    this.registrationForm.Ermaessigungen.MitgliedPfarrgemeinde = $event.checked;
    this.calculateErmaessigungen();
  }

  taetigkeitenChanged(): void {
    this.calculateErmaessigungen();
  }

  setArbeitslosengeld($event: MatCheckboxChange): void {
    this.registrationForm.Ermaessigungen.Arbeitslosengeld = $event.checked;
    this.calculateErmaessigungen();
  }

  setNachlaesseBetrag($event: number): void {
    this.calculateErmaessigungen();
  }

  setSonstigeNachlaesse($event: MatCheckboxChange): void {
    if (!$event.checked) {
      this.registrationForm.Ermaessigungen.SonstigeNachlaesseBetrag = 0;
      this.registrationForm.Ermaessigungen.SonstigeNachlaesseBegruendung = '';
    }

    this.registrationForm.Ermaessigungen.SonstigeNachlaesse = $event.checked;
    this.calculateErmaessigungen();
  }

  private SetAgeProperties(): void {
    if (this.age && this.age < 12) {
      this.registrationForm.Vorlieben.Klettern = false;
    }
  }

  private setTeilzeitDates(): void {
    if (
      this.teilzeitRange.controls.start.value &&
      this.teilzeitRange.controls.end.value
    ) {
      this.registrationForm.Teilnahme.TeilnahmeVon =
        this.teilzeitRange.controls.start.value;
      this.registrationForm.Teilnahme.TeilnahmeBis =
        this.teilzeitRange.controls.end.value;
    }
  }

  private setTeilzeitRange(): void {
    this.teilzeitRange.reset();
    this.teilzeitRange.controls.start.setValue(
      this.registrationForm.Teilnahme.TeilnahmeVon
    );
    this.teilzeitRange.controls.end.setValue(
      this.registrationForm.Teilnahme.TeilnahmeBis
    );
  }

  private setUnterbrechungDates(): void {
    if (
      this.unterbrechungRange.controls.start.value &&
      this.unterbrechungRange.controls.end.value
    ) {
      this.registrationForm.Teilnahme.TeilnahmeVonZweiterZeitraum =
        this.unterbrechungRange.controls.start.value;
      this.registrationForm.Teilnahme.TeilnahmeBisZweiterZeitraum =
        this.unterbrechungRange.controls.end.value;
    }
  }

  private setUnterbrechungRange(): void {
    this.unterbrechungRange.reset();
    this.unterbrechungRange.controls.start.setValue(
      this.registrationForm.Teilnahme.TeilnahmeVonZweiterZeitraum
    );
    this.unterbrechungRange.controls.end.setValue(
      this.registrationForm.Teilnahme.TeilnahmeBisZweiterZeitraum
    );
  }

  private isFormValid(): boolean {
    if (
      this.form.status === 'VALID' &&
      this.teilzeitRange.status === 'VALID' &&
      this.unterbrechungRange.status === 'VALID' &&
      this.geburtsdatum.status === 'VALID' &&
      this.juLeiCaGueltigBisDatum.status === 'VALID'
    ) {
      return true;
    }

    return false;
  }

  private setGeburtsdatumForm(): void {
    this.geburtsdatum.reset();
    this.geburtsdatum.setValue(this.registrationForm.Person.Geburtsdatum);
  }

  private setGeburtsdatum(geburtsdatum: string | null): void {
    if (geburtsdatum) {
      this.registrationForm.Person.Geburtsdatum = geburtsdatum;
      this.age = this.calculateAge(new Date(geburtsdatum));
      this.isChild = this.age < 16;
      this.registrationForm.Person.Alter = this.age;

      this.SetIsChildProperties();
      this.SetAgeProperties();
    }
  }

  private setJuLeiCaGueltigBisDatumForm(): void {
    if (this.registrationForm.Betreuer.JuLeiCaGueltigBis) {
      this.juLeiCaGueltigBisDatum.reset();
      this.juLeiCaGueltigBisDatum.setValue(
        this.registrationForm.Betreuer.JuLeiCaGueltigBis
      );
    }
  }

  private setJuLeiCaGueltigBisDatum(date: string | null): void {
    if (date) {
      console.log('set juleica date: ' + date);
      this.registrationForm.Betreuer.JuLeiCaGueltigBis = date;
    }
  }
}
