import { Component, OnInit } from '@angular/core';
import * as appData from '../../assets/data/appData.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  register = false;
  appData = appData;

  constructor() {}

  ngOnInit(): void {}

  showRegistration(show: boolean): void {
    /*this.router.navigate(['signup']);*/
    this.register = show;
  }
}
