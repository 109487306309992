import { FormControl } from '@angular/forms';

export enum Teilnahmedauer {
  Vollzeit = 'Vollzeit',
  Teilzeit = 'Teilzeit',
  Unterbrechung = 'Unterbrechung',
  Party = 'Party',
}

export enum Einnahmeform {
  Selbstaendig = 'Die Medikamente nehme ich selbständig ein.',
  Erinnert = 'Mein Betreuer soll mich an meine Medikamente erinnern.',
  Betreuer = 'Die Medikamente erhält mein Betreuer und gibt sie mir.',
}

export enum Geschlecht {
  Maennlich = 'männlich',
  Weiblich = 'weiblich',
  Unbekannt = 'unbekannt',
}

export interface AttendeeForm {
  AttendeeId: string;

  Person: {
    Vorname: string;
    Nachname: string;
    Geschlecht: Geschlecht;
    Geburtsdatum: string;
    Alter: number;
  };

  Kontakt: {
    Handy: string;
    Email: string;
  };

  Teilnahme: {
    Erstteilnahme: boolean;
    Teilnahmedauer: Teilnahmedauer;
    TeilnahmeVon: string;
    TeilnahmeBis: string;
    KommentarErsterZeitraum: string;

    TeilnahmeVonZweiterZeitraum: string;
    TeilnahmeBisZweiterZeitraum: string;
    KommentarZweiterZeitraum: string;

    Nachlager: boolean;
    TeilnahmeTage: number;
  };

  Betreuer: {
    Betreut: boolean;
    JuLeiCa: boolean;
    JuLeiCaTraeger: string;
    JuLeiCaGueltigBis: string;
    JuLeiCaAngemeldet: boolean;
    JuLeiCaAngemeldetKurs: string;
    POK: boolean;
    VorerfahrungJugendarbeit: boolean;
    VorerfahrungsList: string;
    Winterroeder: boolean;
    Lenzroeder: boolean;
  };

  Zelt: {
    ZeltVorhanden: boolean;
    ZeltPersonen: number;
    ZeltBreite: number;
    ZeltLaenge: number;
    Zeltpartner: string;
  };

  Gesundheit: {
    Krankenkasse: string;

    Hausarzt: {
      HausarztName: string;
      HausarztAdresse: string;
      HausarztTelefonnummer: string;
    };

    Tetanusimpfung: boolean;
    Krankheiten: string;

    Medikamente: {
      MedikamenteErforderlich: boolean;
      Medikamentenliste: string;
      MedikamenteEinnahme: Einnahmeform;
    };

    Allergien: string;
    Roentgen: boolean;
  };

  Vorlieben: {
    Vegtarisch: boolean;
    DarfSchwimmen: boolean;
    Schwimmabzeichen: string;
    Klettern: boolean;
    Segelfliegen: boolean;
    Wasserski: boolean;
  };

  Ermaessigungen: {
    Ermaessigt: boolean;
    MitgliedPfarrgemeinde: boolean;
    NamePfarrgemeinde: string;
    AnzahlTaetigkeiten: number;
    Arbeitslosengeld: boolean;
    SonstigeNachlaesse: boolean;
    SonstigeNachlaesseBetrag: number;
    SonstigeNachlaesseBegruendung: string;
    SummeErmaessigungen: number;
  };

  Sonstiges: {
    SonstigesText: string;
    Praeventionskurs: boolean;
  };
}
