import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateGeneralComponent } from './registration/create-general/create-general.component';
import { CreateRegistrationComponent } from './registration/create-registration/create-registration.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'start', component: HomeComponent },
  {
    path: 'kontakt',
    component: CreateGeneralComponent,
  },
  {
    path: 'teilnehmer/:attendeeId',
    component: CreateRegistrationComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
