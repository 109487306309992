import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-send-enrollment',
  templateUrl: './dialog-send-enrollment.component.html',
  styleUrls: ['./dialog-send-enrollment.component.scss'],
})
export class DialogSendEnrollmentComponent implements OnInit {
  keepData = true;
  constructor() {}

  ngOnInit(): void {}
}
