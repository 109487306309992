<mat-card *ngIf="appData.testphase" class="warningCard">
  <mat-card-title>TESTPHASE</mat-card-title>
  <mat-card-content>
    <div fxLayout="row">
      <img src="../../assets/img/construction.png" alt="Testphase" class="logo">
      <span>Die RöderApp wird momentan überarbeitet. Eine Anmeldungen sind erst ab dem {{anmeldestart}} möglich. Zuvor versendete Anmeldungen werden nicht übernommen.</span
      >
    </div>
  </mat-card-content>
</mat-card>
