<table mat-table [dataSource]="teilnehmer" class="mat-elevation-z4">
  <!-- AttendeeName Column -->
  <ng-container matColumnDef="attendeeName">
    <th mat-header-cell *matHeaderCellDef>Teilnehmer</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <!-- Cost Column -->
  <ng-container matColumnDef="cost">
    <th mat-header-cell *matHeaderCellDef>Kosten</th>
    <td mat-cell *matCellDef="let element">{{ element.kosten }}€</td>
  </ng-container>

  <!-- Reduction Column -->
  <ng-container matColumnDef="reduction">
    <th mat-header-cell *matHeaderCellDef>Ermäßigungen</th>
    <td mat-cell *matCellDef="let element">{{ element.ermaessigungen }}€</td>
  </ng-container>

  <!-- Action Column -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>Aktionen</th>
    <td mat-cell *matCellDef="let element">{{ element.aktionen }}€</td>
  </ng-container>

  <!-- Sum Column -->
  <ng-container matColumnDef="sum">
    <th mat-header-cell *matHeaderCellDef>Summe</th>
    <td mat-cell *matCellDef="let element">{{ element.summe }}€</td>
  </ng-container>

  <!-- Footer Columns -->
  <!-- Footer Column ErmaessigungKinder -->
  <ng-container matColumnDef="ermaessigungKinder">
    <td mat-footer-cell *matFooterCellDef>Ermäßigung für weitere Kinder</td>
  </ng-container>

  <!-- Footer Column ZuschlagNachlager -->
  <ng-container matColumnDef="zuschlagNachlager">
    <td mat-footer-cell *matFooterCellDef>
      <span>Zuschlag Nachlager (ab 16 Jahren, 20€ pro Person)</span>
      <mat-icon
        style="margin-left: 16px"
        matSuffix
        matTooltipClass="tooltip"
        matTooltip="Das Nachlager findet ab Freitag (letzter Tag des Lagers) bis Samstagabend statt. Jeder Teilnehmer, welcher zur Zeit des Hauptlagers 16 Jahre oder älter ist, soll am Nachlager teilnehmen. Sollte der Teilnehmer teilnehmen, erhält er den Zuschlag in Höhe von 20€ für das Nachlager wieder zurück."
        >info_outline</mat-icon
      >
    </td>
  </ng-container>

  <!-- Footer Column ZuschlagVerspaetung -->
  <ng-container matColumnDef="zuschlagVerspaetung">
    <td mat-footer-cell *matFooterCellDef>Zuschlag verspätete Anmeldung</td>
  </ng-container>

  <!-- Footer Column Total -->
  <ng-container matColumnDef="total">
    <td mat-footer-cell *matFooterCellDef>Gesamtkosten</td>
  </ng-container>

  <!-- empty footer row-->
  <ng-container matColumnDef="emptyColumn">
    <td mat-footer-cell *matFooterCellDef></td>
  </ng-container>

  <!-- Footer Column ErmaessigungKinderSum -->
  <ng-container matColumnDef="ermaessigungKinderSum">
    <td mat-footer-cell *matFooterCellDef>{{ ermaessigungKinder }}€</td>
  </ng-container>

  <!-- Footer Column ZuschlagNachlagerSum -->
  <ng-container matColumnDef="zuschlagNachlagerSum">
    <td mat-footer-cell *matFooterCellDef>{{ zuschlagNachlager }}€</td>
  </ng-container>

  <!-- Footer Column ZuschlagVerspaetungValue -->
  <ng-container matColumnDef="zuschlagVerspaetungValue">
    <td mat-footer-cell *matFooterCellDef>{{ zuschlagVerspaetung }}€</td>
  </ng-container>

  <!-- Footer Column TotalValue -->
  <ng-container matColumnDef="totalValue">
    <td mat-footer-cell *matFooterCellDef>
      <b>{{ gesamtSumme }}€</b>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumnsErmaessigungKinder"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumnsZuschlagNachlager"></tr>
  <tr
    mat-footer-row
    *matFooterRowDef="displayedColumnsZuschlagVerspaetung"
  ></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumnsTotal"></tr>
</table>
