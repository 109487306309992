<section>
  <table mat-table [dataSource]="attendeeList" class="mat-elevation-z4">
    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.Name }}</td>
    </ng-container>

    <!-- Bezeichnung Column -->
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef>Bezeichnung</th>
      <td mat-cell *matCellDef="let element">{{ element.Category }}</td>
    </ng-container>

    <!-- Teilnahme Column -->
    <ng-container matColumnDef="teilnahme">
      <th mat-header-cell *matHeaderCellDef>Teilnahme</th>
      <td mat-cell *matCellDef="let element">
        {{ element.Teilnahme }}
      </td>
    </ng-container>

    <!-- Menu Column -->
    <ng-container matColumnDef="menu">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row" class="fit-content">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="openRegistrationForm(row.AttendeeId)">
            <mat-icon>edit</mat-icon>
            Bearbeiten
          </button>

          <button mat-menu-item (click)="deleteAttendeeById(row.AttendeeId)">
            <mat-icon>delete</mat-icon>
            <span>Entfernen</span>
          </button>
        </mat-menu>
      </td></ng-container
    >

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</section>
