import { Component, OnInit } from '@angular/core';
import * as appData from "../../../assets/data/appData.json";

@Component({
  selector: 'app-testphase',
  templateUrl: './testphase.component.html',
  styleUrls: ['./testphase.component.scss']
})
export class TestphaseComponent implements OnInit {
  appData = appData;
  anmeldestart = new Date(this.appData.daten.anmeldestart).toLocaleDateString('de-DE');
  constructor() { }

  ngOnInit(): void {
  }
}
