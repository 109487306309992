import { NgModule } from '@angular/core';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import {FormPageComponent} from './form.page.component';


@NgModule({
  declarations: [
    // page
    FormPageComponent,
  ],
  imports: [
    FlexModule,
    FlexLayoutModule,
  ],
  exports: [
    // page
    FormPageComponent,
  ],
})
export class LayoutModule {}
