<div class="banner">
    <img src="../../assets/img/roederLogo_fill2.png" alt="Zeltlager Röderhof Logo" class="logo">

    <span>Zeltlager Röderhof 2024 der kath. Pfarrei St. Bernward Lehrte</span>

</div>

<div>
  <ng-content></ng-content>
</div>

    <div>
      <p class="referencePara">
        <a
          href="../../assets/downloads/Impressum.pdf"
          download
          class="reference"
        >Impressum</a
        >.
      </p>
      <p class="referencePara link">
        <a
          class="link"
          href="../../assets/downloads/Datenschutzerklaerung.pdf"
          class="reference"
          download
        >Datenschutz</a
        >.
      </p>
    </div>
