import { Injectable } from '@angular/core';
import { CostList } from './costList';
import { RegistrationService } from '../../../../registration/registration-service/registration.service';
import {
  AttendeeForm,
  Teilnahmedauer,
} from '../../../../registration/registration-service/attendee.form';
import { CostListEntry } from './costListEntry';
import * as appData from '../../../../../assets/data/appData.json';

@Injectable({
  providedIn: 'root',
})
export class CostService {
  appData = appData;

  constructor() {}

  getCostList(attendees: AttendeeForm[]): CostList {
    const result: CostList = {
      Teilnehmer: [],
      ErmaessigungKinder: 0,
      ZuschlagNachlager: 0,
      ZuschlagVerspaetung: 0,
      Gesamtsumme: 0,
    };

    if (attendees) {
      attendees.forEach((attendee) => {
        result.Teilnehmer.push(this.calculateCostForAttendee(attendee));
      });

      result.ErmaessigungKinder = this.calculateErmaessigungKinder(attendees);
      result.ZuschlagNachlager = this.calculateZuschlagNachlager(attendees);
      result.ZuschlagVerspaetung = this.calculateZuschlagVerspaetung(attendees);
      result.Gesamtsumme = this.calculateTotal(result);
    }

    return result;
  }

  calculateCostForAttendee(item: AttendeeForm): CostListEntry {
    const attendeeCost: CostListEntry = {
      name: '',
      alter: 0,
      ermaessigt: false,
      kosten: 0,
      ermaessigungen: 0,
      aktionen: 0,
      summe: 0,
    };

    attendeeCost.name = item.Person.Vorname + ' ' + item.Person.Nachname;
    attendeeCost.alter = item.Person.Alter;
    attendeeCost.ermaessigt = item.Ermaessigungen.Ermaessigt;

    attendeeCost.kosten = this.calculateCostForDuration(item);
    attendeeCost.ermaessigungen = item.Ermaessigungen.SummeErmaessigungen;
    attendeeCost.aktionen = this.calculateCostForActions(item);

    const summe =
      attendeeCost.kosten + attendeeCost.ermaessigungen + attendeeCost.aktionen;

    if (summe < 50) {
      attendeeCost.summe = 50;
    } else {
      attendeeCost.summe = summe;
    }

    return attendeeCost;
  }

  calculateCostForDuration(item: AttendeeForm): number {
    if (item.Teilnahme.Teilnahmedauer === 'Vollzeit') {
      if (item.Ermaessigungen.Ermaessigt) {
        return 220;
      } else {
        return 250;
      }
    } else if (item.Teilnahme.Teilnahmedauer === 'Party') {
      return 60;
    } else {
      if (item.Ermaessigungen.Ermaessigt) {
        const cost = 22 * item.Teilnahme.TeilnahmeTage;
        if (cost > 220) {
          return 220;
        }
        return cost;
      } else {
        const cost = 25 * item.Teilnahme.TeilnahmeTage;
        if (cost > 250) {
          return 250;
        }
        return cost;
      }
    }
  }

  calculateCostForActions(item: AttendeeForm): number {
    let actionCosts = 0;

    if (item.Vorlieben.Klettern) {
      actionCosts += 20;
    }

    if (item.Vorlieben.Segelfliegen) {
      actionCosts += 20;
    }

    if (item.Vorlieben.Wasserski) {
      actionCosts += 20;
    }

    return actionCosts;
  }

  calculateErmaessigungKinder(data: AttendeeForm[]): number {
    let reductions = 0;
    let count = 0;

    data.forEach((item) => {
      if (
        item.Ermaessigungen.Ermaessigt &&
        (item.Teilnahme.Teilnahmedauer === 'Vollzeit' || count === 0)
      ) {
        count++;
      }
    });

    if (count === 2) {
      reductions = -60;
    } else if (count > 2) {
      reductions = -60 + -80 * (count - 2);
    }

    return reductions;
  }

  calculateZuschlagNachlager(data: AttendeeForm[]): number {
    let costs = 0;

    data.forEach((item) => {
      if (item.Person.Alter >= 16) {
        costs += 20;
      }
    });

    return costs;
  }

  calculateZuschlagVerspaetung(data: AttendeeForm[]): number {
    let costs = 0;
    const today = new Date();
    const deadline = new Date(this.appData.daten.verspaetung);

    if (today > deadline) {
      const count = this.calculateDayDiff(today, deadline);
      costs = count * 5;
    }

    // Todo what is the maximum?

    return costs > 50 ? 50 : costs;
  }

  calculateTotal(result: CostList): number {
    let total = 0;

    result.Teilnehmer.forEach((attendee) => (total += attendee.summe));
    total +=
      result.ErmaessigungKinder +
      result.ZuschlagNachlager +
      result.ZuschlagVerspaetung;

    return total;
  }

  calculateDayDiff(dateToSubtractFrom: Date, dateToSubtract: Date){
        return Math.floor((Date.UTC(dateToSubtractFrom.getFullYear(), dateToSubtractFrom.getMonth(), dateToSubtractFrom.getDate())
          - Date.UTC(dateToSubtract.getFullYear(), dateToSubtract.getMonth(), dateToSubtract.getDate()) ) /(1000 * 60 * 60 * 24));
  }
}
