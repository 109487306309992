<app-form-page>
  <div fxLayout="column">
    <app-general-form
      #generalForm
      (submitted)="createGeneral($event)"
    ></app-general-form>
    <span class="warningText"
      *ngIf="generalForm.invalid"
      >Bitte fülle vor dem Speichern alle mit * gekennzeichnete Felder aus</span
    >
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutGap.xs="8px">
      <button
        mat-flat-button
        color="primary"
        fxFlex="nogrow"
        type="submit"
        (click)="generalForm.submit()"
        [disabled]="generalForm.invalid || disableAfterSave"
      >
        Speichern
      </button>

      <button
        mat-flat-button
        fxFlex="nogrow"
        type="cancel"
        (click)="openStartpage()"
      >
        Abbrechen
      </button>
    </div>
  </div>
</app-form-page>
