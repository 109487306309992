import { Component, OnInit } from '@angular/core';
import { CostListEntry } from './cost-service/costListEntry';
import { CostService } from './cost-service/cost.service';
import { RegistrationService } from '../../../registration/registration-service/registration.service';

@Component({
  selector: 'app-cost-list',
  templateUrl: './cost-list.component.html',
  styleUrls: ['./cost-list.component.scss'],
})
export class CostListComponent implements OnInit {
  teilnehmer: CostListEntry[] = [];
  ermaessigungKinder = 0;
  zuschlagNachlager = 0;
  zuschlagVerspaetung = 0;
  gesamtSumme = 0;

  displayedColumns: string[] = [
    'attendeeName',
    'cost',
    'reduction',
    'action',
    'sum',
  ];

  displayedColumnsErmaessigungKinder: string[] = [
    'emptyColumn',
    'emptyColumn',
    'emptyColumn',
    'ermaessigungKinder',
    'ermaessigungKinderSum',
  ];

  displayedColumnsZuschlagNachlager: string[] = [
    'emptyColumn',
    'emptyColumn',
    'emptyColumn',
    'zuschlagNachlager',
    'zuschlagNachlagerSum',
  ];

  displayedColumnsZuschlagVerspaetung: string[] = [
    'emptyColumn',
    'emptyColumn',
    'emptyColumn',
    'zuschlagVerspaetung',
    'zuschlagVerspaetungValue',
  ];

  displayedColumnsTotal: string[] = [
    'emptyColumn',
    'emptyColumn',
    'emptyColumn',
    'total',
    'totalValue',
  ];

  constructor(
    private costService: CostService,
    private registrationService: RegistrationService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  private loadData(): void {
    const attendees = this.registrationService.getAllAttendees();

    if (attendees) {
      const list = this.costService.getCostList(attendees);
      this.teilnehmer = list.Teilnehmer;
      this.ermaessigungKinder = list.ErmaessigungKinder;
      this.zuschlagNachlager = list.ZuschlagNachlager;
      this.zuschlagVerspaetung = list.ZuschlagVerspaetung;
      this.gesamtSumme = list.Gesamtsumme;
    }
  }
}
