import { Component, OnInit } from '@angular/core';
import { AttendeeForm } from '../registration-service/attendee.form';
import { RegistrationService } from '../registration-service/registration.service';
import { Router } from '@angular/router';
import { GeneralForm } from '../registration-service/general.form';

@Component({
  selector: 'app-create-general',
  templateUrl: './create-general.component.html',
  styleUrls: ['./create-general.component.scss'],
})
export class CreateGeneralComponent implements OnInit {
  public disableAfterSave = false;

  constructor(
    private registrationService: RegistrationService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  public createGeneral(form: GeneralForm): void {
    this.disableAfterSave = true;
    setTimeout(() => {
      this.disableAfterSave = false;
    }, 2000);
    this.registrationService.saveGeneral(form);
    this.openStartpage();
  }

  openStartpage(): void {
    this.router.navigate(['home']);
  }
}
