<mat-expansion-panel [expanded]="!disabled" [disabled]="disabled" [ngStyle]="{'backgroundColor': disabled && !isLoading ? 'indianred' : 'white'}">

  <mat-expansion-panel-header>
    <mat-panel-title>{{disabled && !isLoading ? "ANMELDEFRIST ABGELAUFEN" : "Anmeldung starten"}}  </mat-panel-title>
    <mat-panel-description>
      Online Anmeldung für das Zeltlager Röderhof {{appData.daten.jahr}}
    </mat-panel-description>
  </mat-expansion-panel-header>

  <div>
    <div *ngIf="verspaetet">
      <h2 style="color: darkred">Die Anmeldefrist ist abgelaufen</h2>
      <p style="color: darkred">
        {{appData.texte.verspaetung}}
      </p>
    </div>

    <h2>Allgemeine Informationen</h2>
    <mat-toolbar class="am-table-toolbar" fxLayoutGap="24px">
      <div class="fab-button-container">
        <button
          mat-mini-fab
          (click)="openGeneralForm()"
          color="primary"
        >
          <mat-icon>assignment</mat-icon>
        </button>
        <span>Anmeldeformular ausfüllen</span>
      </div>
    </mat-toolbar>

    <h2>Teilnehmer</h2>
    <mat-toolbar class="am-table-toolbar" fxLayoutGap="24px">
      <div class="fab-button-container">
        <button mat-mini-fab (click)="openRegistrationForm('')" color="primary">
          <mat-icon>add</mat-icon>
        </button>
        <span>Familienmitglied hinzufügen</span>
      </div>
    </mat-toolbar>
    <app-attendee-list></app-attendee-list>

    <h2>Kosten</h2>
    <app-cost-list></app-cost-list>
    <p style="margin-top: 30px">
      {{appData.texte.kosten.ueberweissung}}
    </p>
    <p><b>BLZ:</b> 251 933 31<br/>
    <b>Konto-Nr.:</b> 72 066 717 01<br/>
    <b>IBAN:</b> DE76 2519 3331 7206 6717 01<br/>
    <b>BIC:</b> GENODEF1PAT<br/>
      <b>Verwendungszweck:</b> Röderhof {{appData.daten.jahr}} sowie Name und Telefonnr.
    </p>
    <p style="margin-top: 30px">
      {{appData.texte.kosten.unklarheit}}
    </p>

  </div>

  <mat-action-row>
    <div fxLayout="column">
    <div fxLayout="column">
    <span class="warningText"
      *ngIf="!registrationCompleted && !successfullySubmitted"
    >Bitte fülle vor dem Senden die allgemeinen Informationen aus und füge mindestens einen Teilnehmer hinzu.</span
    >
    <div
      class="fab-button-container"
      style="margin-top: 40px; margin-bottom: 40px"
      *ngIf="!successfullySubmitted"
    >

      <button mat-mini-fab (click)="send()" color="primary" [disabled]="!registrationCompleted || isLoading">
          <mat-icon>send</mat-icon>
      </button>
        <span>Anmeldung absenden</span>
      </div>
    </div>
      <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

    <mat-card *ngIf="successfullySubmitted" class="successCard">
      <mat-card-title>Erfolgreiche Anmeldung</mat-card-title>
      <mat-card-content>{{submissionText}}</mat-card-content>
    </mat-card>

    <mat-card *ngIf="submissionError" class="errorCard">
      <mat-card-title>Fehler bei der Anmeldung</mat-card-title>
      <mat-card-content>{{submissionErrorText}}</mat-card-content>
    </mat-card>


    </div>
  </mat-action-row>
</mat-expansion-panel>
