import { Component, OnInit } from '@angular/core';
import {
  AttendeeForm,
  Teilnahmedauer,
} from '../registration-service/attendee.form';
import { RegistrationService } from '../registration-service/registration.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-create-registration',
  templateUrl: './create-registration.component.html',
  styleUrls: ['./create-registration.component.scss'],
})
export class CreateRegistrationComponent implements OnInit {
  public registrationForm: any = {};
  public attendeeId = '';
  public disableAfterSave = false;

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) => (this.attendeeId = params.attendeeId)
    );
  }

  public createRegistration(form: AttendeeForm): void {
    this.disableAfterSave = true;
    setTimeout(() => {
      this.disableAfterSave = false;
    }, 2000);
    this.cleanUnusedFormValues(form);
    this.registrationService.saveAttendee(form);
    this.openStartpage();
  }

  openStartpage(): void {
    this.router.navigate(['home']);
  }

  private cleanUnusedFormValues(form: AttendeeForm): void {
    // NOT Unterbrechung
    if (!(form.Teilnahme.Teilnahmedauer === Teilnahmedauer.Unterbrechung)) {
      form.Teilnahme.KommentarZweiterZeitraum = '';
      form.Teilnahme.TeilnahmeVonZweiterZeitraum = '';
      form.Teilnahme.TeilnahmeBisZweiterZeitraum = '';
    }

    // NOT Teilzeit || Unterbrechung
    if (
      !(
        form.Teilnahme.Teilnahmedauer === Teilnahmedauer.Teilzeit ||
        form.Teilnahme.Teilnahmedauer === Teilnahmedauer.Unterbrechung
      )
    ) {
      form.Teilnahme.KommentarErsterZeitraum = '';
      form.Teilnahme.TeilnahmeVon = '';
      form.Teilnahme.TeilnahmeBis = '';
    }

    // NOT Zelt.Vorhanden
    if (!form.Zelt.ZeltVorhanden) {
      form.Zelt.ZeltPersonen = 0;
      form.Zelt.ZeltLaenge = 0;
      form.Zelt.ZeltBreite = 0;
    }

    // NOT Betreuer.Betreut
    if (!form.Betreuer.Betreut) {
      form.Betreuer.JuLeiCa = false;
      form.Betreuer.JuLeiCaTraeger = '';
      form.Betreuer.JuLeiCaAngemeldet = false;
      form.Betreuer.POK = false;
      form.Betreuer.VorerfahrungJugendarbeit = false;
      form.Betreuer.VorerfahrungsList = '';
      form.Betreuer.Winterroeder = false;
      form.Betreuer.Lenzroeder = false;
      form.Betreuer.JuLeiCaAngemeldetKurs = '';
    }
    else{
      // Not JuLeiCa
      if (!form.Betreuer.JuLeiCa) {
        form.Betreuer.JuLeiCaGueltigBis = '';
        form.Betreuer.JuLeiCaTraeger = '';
      }

      // NOT Betreuer.Angemeldet
      if (!form.Betreuer.JuLeiCaAngemeldet) {
        form.Betreuer.JuLeiCaAngemeldetKurs = '';
      }

      // NOT Betreuer.Vorerfahrung
      if (!form.Betreuer.VorerfahrungJugendarbeit) {
        form.Betreuer.VorerfahrungsList = '';
      }
    }


    // NOT Vorlieben.Schwimmen
    if (!form.Vorlieben.DarfSchwimmen) {
      form.Vorlieben.Schwimmabzeichen = '';
    }

    // NOT Medikamente.Erforderlich
    if (!form.Gesundheit.Medikamente.MedikamenteErforderlich) {
      form.Gesundheit.Medikamente.Medikamentenliste = '';
    }

    // NOT Teilnahmedauer.Vollzeit
    if (!(form.Teilnahme.Teilnahmedauer === Teilnahmedauer.Vollzeit)) {
      form.Ermaessigungen.MitgliedPfarrgemeinde = false;
      form.Ermaessigungen.AnzahlTaetigkeiten = 0;
      form.Ermaessigungen.Arbeitslosengeld = false;
      form.Ermaessigungen.SonstigeNachlaesse = false;
      form.Ermaessigungen.SummeErmaessigungen = 0;
      form.Ermaessigungen.SonstigeNachlaesseBetrag = 0;
      form.Ermaessigungen.SonstigeNachlaesseBegruendung = '';
      form.Ermaessigungen.NamePfarrgemeinde = '';
    }
    // NOT Ermaessigungen.MitgliedPfarrgemeinde
    else if (!form.Ermaessigungen.MitgliedPfarrgemeinde) {
      form.Ermaessigungen.NamePfarrgemeinde = '';
    }
    // NOT Ermaessigungen.SonstigeNachlaesse
    else if (!form.Ermaessigungen.SonstigeNachlaesse) {
      form.Ermaessigungen.SonstigeNachlaesseBetrag = 0;
      form.Ermaessigungen.SonstigeNachlaesseBegruendung = '';
    }
  }
}
