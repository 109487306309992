<h2 mat-dialog-title>Möchtest du den Teilnehmer wirklich entfernen?</h2>

<mat-dialog-content>
  <p>Warnung: Alle Daten des Teilnehmers werden gelöscht.</p>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Abbrechen</button>
  <button mat-button color="warn" cdkFocusInitial [mat-dialog-close]="true">
    Entfernen
  </button>
</mat-dialog-actions>
