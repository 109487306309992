<h2 mat-dialog-title>Möchtest du die Anmeldung abschicken?</h2>

<mat-dialog-content>
  <div fxLayout="column">
    <p>
      Warnung: Bitte sende die Anmeldung erst ab wenn alle Anmeldedaten
      vollständig sind. Eine nachträgliche Änderung ist nicht möglich.
    </p>

    <mat-checkbox [checked]="true" disabled
      >Mit meiner Anmeldung erkläre ich mich damit einverstanden, dass meine
      Daten bzw. die meines Kindes zur Verwaltung und Abrechnung des Zeltlagers
      mit der EDV erfasst werden. Außerdem stimme ich zu, dass während des
      Lagers Fotos und Videos aufgenommen werden, auf denen die Teilnehmer zu
      sehen sind. Diese Fotos und Videos werden zum Teil gespeichert und auf
      späteren Treffen (z.B. Röderparty) gezeigt. Ein geringer Teil von
      ausgesuchten Fotos können auf der Internetseite der Pfarrei bzw. des
      Zeltlagers als Dokumentation bzw. als Werbung für zukünftige
      Ferienfreizeiten eingestellt werden.</mat-checkbox
    >
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="null">Abbrechen</button>
  <button
    mat-button
    color="primary"
    cdkFocusInitial
    [mat-dialog-close]="keepData"
  >
    Senden
  </button>
</mat-dialog-actions>
