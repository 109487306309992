import { HttpClient, HttpResponse } from '@angular/common/http';
import { AttendeeForm, Teilnahmedauer } from './attendee.form';
import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { GeneralForm } from './general.form';
import { AttendeeListEntry } from '../../home/dashboard/attendee-list/attendeeListEntry';
import { Category } from '../../home/dashboard/attendee-list/category';
import { v4 as uuidv4 } from 'uuid';
import { EncryptionService } from '../encryption-service/encryption.service';
import { CompleteForm } from './complete.form';
import { CostList } from '../../home/dashboard/cost-list/cost-service/costList';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  private submitUrl =
    'https://md1xxum5n5.execute-api.eu-central-1.amazonaws.com/prod/submit';

  private generalFormLocalStorageKey = 'generalForm';
  private attendeeFormLocalStorageKey = 'attendeeForm';

  constructor(
    private http: HttpClient,
    private encryptionService: EncryptionService
  ) {}

  saveGeneral(form: GeneralForm): void {
    const encrypted = this.encryptionService.encrypt(JSON.stringify(form));
    localStorage.setItem(this.generalFormLocalStorageKey, encrypted);
  }

  getGeneral(): GeneralForm | undefined {
    return this.getDecryptedDataFromStorage(
      this.generalFormLocalStorageKey
    ) as GeneralForm;
  }

  saveAttendee(form: AttendeeForm): void {
    if (!form.AttendeeId) {
      form.AttendeeId = uuidv4();
    }

    form.Teilnahme.TeilnahmeTage = this.calculateDays(form);

    let attendees = this.getAllAttendees();

    if (attendees) {
      const index = attendees.findIndex(
        (a) => a.AttendeeId === form.AttendeeId
      );

      if (index >= 0) {
        /*Replace attendee in list*/
        attendees[index] = form;
      } else {
        /*Add new attendee to list*/
        attendees.push(form);
      }
    } else {
      /*First attendee in list*/
      attendees = [form];
    }

    this.setAttendees(attendees);
  }

  deleteAttendee(attendeeId: string): void {
    const attendees = this.getAllAttendees();

    if (attendees?.length === 1 && attendees[0].AttendeeId === attendeeId) {
      localStorage.removeItem(this.attendeeFormLocalStorageKey);
    } else {
      const remainingAttendees = attendees?.filter(
        (x) => x.AttendeeId !== attendeeId
      );

      if (remainingAttendees && remainingAttendees.length > 0) {
        this.setAttendees(remainingAttendees);
      }
    }
  }

  getAttendee(attendeeId: string): AttendeeForm | undefined {
    const attendees = this.getAllAttendees();

    if (attendees) {
      return attendees.find((x) => x.AttendeeId === attendeeId);
    }

    return undefined;
  }

  getAllAttendees(): AttendeeForm[] | undefined {
    return this.getDecryptedDataFromStorage(
      this.attendeeFormLocalStorageKey
    ) as AttendeeForm[];
  }

  getAttendeeList(): AttendeeListEntry[] {
    const attendees = this.getAllAttendees();

    if (!attendees) {
      return [];
    }

    return attendees.map((x) => ({
      AttendeeId: x.AttendeeId,
      Name: x.Person.Vorname,
      Category: this.getAttendeeCategory(x),
      Teilnahme: x.Teilnahme.Teilnahmedauer,
    })) as AttendeeListEntry[];
  }

  isRegistrationComplete(): boolean {
    return (
      localStorage.getItem(this.attendeeFormLocalStorageKey) !== null &&
      localStorage.getItem(this.generalFormLocalStorageKey) !== null
    );
  }

  submit(costList: CostList): Observable<{ success: boolean; status: number }> {
    if (this.isRegistrationComplete()) {
      const completeForm = this.getCompleteForm(costList);

      if (completeForm) {
        this.convertDatesToLocalDates(completeForm);
        return this.http
          .post(
            'https://md1xxum5n5.execute-api.eu-central-1.amazonaws.com/prod/pipeline',
            completeForm,
            {
              observe: 'response',
            }
          )
          .pipe(
            map((response: HttpResponse<object>) => {
              this.clearStorage();
              return { success: true, status: response.status };
            })
          )
          .pipe(
            catchError((err) => {
              console.log('Handling error locally and rethrowing it...', err);
              return of({ success: false, status: err.status });
            })
          );
      }
    }

    return of({ success: false, status: 0 });
  }

  getGeneralEmail(): string | undefined {
    console.log('reg email: ' + this.getGeneral()?.Kontakt.Email);
    return this.getGeneral()?.Kontakt.Email;
  }

  private getCompleteForm(costList: CostList): CompleteForm | undefined {
    const general = this.getGeneral();
    const attendees = this.getAllAttendees();

    if (general && attendees) {
      return {
        general,
        attendees,
        cost: costList,
      };
    }

    return undefined;
  }

  private setAttendees(attendees: AttendeeForm[]): void {
    const encrypted = this.encryptionService.encrypt(JSON.stringify(attendees));

    localStorage.setItem(this.attendeeFormLocalStorageKey, encrypted);
  }

  private getAttendeeCategory(attendee: AttendeeForm): Category {
    if (attendee.Betreuer.Betreut) {
      return Category.Carer;
    } else if (attendee.Person.Alter >= 18) {
      return Category.Adult;
    }

    return Category.Child;
  }

  private getDecryptedDataFromStorage(storageKey: string): unknown {
    const decrypted = localStorage.getItem(storageKey) || undefined;

    if (decrypted) {
      const data = this.encryptionService.decrypt(decrypted);
      return JSON.parse(data);
    }

    return undefined;
  }

  private calculateDays(attendee: AttendeeForm): number {
    if (attendee.Teilnahme.Teilnahmedauer === Teilnahmedauer.Vollzeit) {
      return 14;
    } else if (attendee.Teilnahme.Teilnahmedauer === Teilnahmedauer.Party) {
      return 3;
    } else if (attendee.Teilnahme.Teilnahmedauer === Teilnahmedauer.Teilzeit) {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(
        Math.abs(
          new Date(attendee.Teilnahme.TeilnahmeVon).getTime() -
            new Date(attendee.Teilnahme.TeilnahmeBis).getTime()
        ) / oneDay
      );
      return diffDays + 1;
    } else if (
      attendee.Teilnahme.Teilnahmedauer === Teilnahmedauer.Unterbrechung
    ) {
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const diffDays = Math.round(
        Math.abs(
          new Date(attendee.Teilnahme.TeilnahmeVon).getTime() -
            new Date(attendee.Teilnahme.TeilnahmeBis).getTime()
        ) / oneDay
      );
      const diffDaysSecond = Math.round(
        Math.abs(
          new Date(attendee.Teilnahme.TeilnahmeVonZweiterZeitraum).getTime() -
            new Date(attendee.Teilnahme.TeilnahmeBisZweiterZeitraum).getTime()
        ) / oneDay
      );
      return diffDays + diffDaysSecond + 2;
    }

    return 14;
  }

  private convertDatesToLocalDates(completeForm: CompleteForm): void {
    for (const attendee of completeForm.attendees) {
      attendee.Teilnahme.TeilnahmeVon = this.convertLocalDateIfValidDate(
        attendee.Teilnahme.TeilnahmeVon
      );
      attendee.Teilnahme.TeilnahmeBis = this.convertLocalDateIfValidDate(
        attendee.Teilnahme.TeilnahmeBis
      );
      attendee.Teilnahme.TeilnahmeVonZweiterZeitraum =
        this.convertLocalDateIfValidDate(
          attendee.Teilnahme.TeilnahmeVonZweiterZeitraum
        );
      attendee.Teilnahme.TeilnahmeBisZweiterZeitraum =
        this.convertLocalDateIfValidDate(
          attendee.Teilnahme.TeilnahmeBisZweiterZeitraum
        );

      attendee.Person.Geburtsdatum = this.convertLocalDateIfValidDate(
        attendee.Person.Geburtsdatum
      );

      attendee.Betreuer.JuLeiCaGueltigBis = this.convertLocalDateIfValidDate(
        attendee.Betreuer.JuLeiCaGueltigBis
      );
    }
  }

  private convertLocalDateIfValidDate(value: string): string {
    if (value) {
      return new Date(value).toLocaleDateString('de-DE');
    }
    return '';
  }

  private clearStorage(): void {
    localStorage.removeItem(this.attendeeFormLocalStorageKey);
    localStorage.removeItem(this.generalFormLocalStorageKey);
  }
}
